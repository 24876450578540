import './group-selector.css'
import React from 'react'

export default class GroupSelector extends React.Component {
    constructor(props) {
        super(props);
        this.handleGroupClick = this.handleGroupClick.bind(this);

        this.state = {
            selectedGroup: undefined,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedGroup === state.selectedGroup) {
            return {};
        }
        return {
            selectedGroup: props.selectedGroup,
        };
    }

    handleGroupClick(event) {
        this.props.onGroupChange(parseInt(event.target.id));
    }

    render() {

        let keys = [...this.props.groups.keys()];

        return (
            <div className='group-selector-box'>
                {
                    keys.map(key =>
                        <button
                            id={key}
                            key={key}
                            className={this.state.selectedGroup === key ? 'group-header-selected' : 'group-header'}
                            onClick={this.handleGroupClick}
                        >
                            <div id={key} className='group-header-title'>
                                {this.props.groups.get(key).title}
                            </div>
                            <div id={key} className='group-header-count'>
                                {' (' + this.props.groups.get(key).videos.length + ')'}
                            </div>
                        </button>
                    )
                }
            </div>
        )
    }
}
