import './video.css'
import React from 'react';

export default class Video extends React.Component {

    isMultiple(string) {
        if (!string) {
            return false;
        }
        return string.split(',').length > 1;
    }

    addSpaces(string) {
        if (!string) {
            return string;
        }
        let splits = string.split(',');
        if (splits.length === 1) {
            return string;
        }
        return splits.join(', ');
    }

    render() {
        let url =
            'https://www.beautiesinmovies.com/videos/' +
            this.props.video.year +
            '/' +
            this.props.video.file;

        return (
            <div className='video-box'>
                <div className='movie'>
                    {this.props.video.year + ' - ' + this.props.video.movie}
                </div>
                <div className='details-row'>
                    <div className='details-title'>
                        {'Director' + (this.isMultiple(this.props.video.directors) ? 's' : '') + ':'}
                    </div>
                    <div className='details-content'>
                        {this.addSpaces(this.props.video.directors)}
                    </div>
                </div>
                {
                    this.props.video.actors && 
                    <div className='details-row'>
                        <div className='details-title'>
                            Cast:
                        </div>
                        <div className='details-content'>
                            {this.addSpaces(this.props.video.actors)}
                        </div>
                    </div>
                }
                <video
                    className='video'
                    controls
                    controlsList='nodownload'
                    poster={url + '.jpg'}
                >
                    <source
                        src={url + '.mp4'}
                        type="video/mp4"
                    />
                </video>
            </div>
        )
    }
}
