import './videos.css'
import React from 'react';
import Video from './video.js'

export default function Videos(props) {
    return (
        <div className='videos-box'>
            {
                props.videoSelection.map(video =>
                    <Video key={video} video={props.videos.get(parseInt(video))} />
                )
            }
        </div>
    )
}
