import './home.css'
import React from 'react';
import * as Constants from './common/constants'
import * as Document from './common/document'
import DataApi from './common/data-api'
import { Tabs } from './common/tabs'
import GroupSelector from './components/group-selector'
import TabSelector from './components/tab-selector'
import Videos from './components/videos'

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.handleData = this.handleData.bind(this);
        this.handleGroupData = this.handleGroupData.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);

        let groups = new Map();
        [...Tabs.keys()].forEach(tab => {
            groups.set(tab, undefined);
        })

        let browserState = this.extractBrowserState();

        if (browserState.tab === undefined) {
            Document.updateHeader(
                Constants.siteName + ' - Page not found',
                'Page not found',
                '/404'
            );
        }

        this.state = {
            data: undefined,
            videos: undefined,
            groups: groups,
            browserState: browserState,
        };

        window.addEventListener("popstate", (event) => {
            this.handleNewBrowserState(event.state);
        });
    }

    extractBrowserState() {
        let path = window.location.pathname.split('/');
        let tab = undefined;
        let group = undefined;
        let groupName = undefined;
        if (path[1] === '') {
            tab = 'years';
            if (this.state && this.state.groups) {
                group = this.state.groups.get(tab) === undefined
                    ? undefined
                    : this.state.groups.get(tab).keys().next().value;
                groupName = this.state.groups.get(tab) === undefined
                    ? undefined
                    : this.state.groups.get(tab).values().next().value.title;
            }
        } else {
            tab = path[1].toLowerCase();
            if (Tabs.get(tab) === undefined) {
                tab = undefined;
            } else {
                group = parseInt(path[2]);
                if (this.state &&
                    this.state.groups &&
                    this.state.groups.get(tab) &&
                    this.state.group.get(tab).get(group)) {
                    groupName = this.state.group.get(tab).get(group).title;
                }
            }
        }
        return { tab, group, groupName };
    }

    handleNewBrowserState(state) {
        if (!state) {
            state = this.extractBrowserState();
        }
        Document.setTitle(state);
        this.setState({
            browserState: state,
        });
    }

    handleData(data) {
        let videos = new Map();
        data.forEach(video => videos.set(video['id'], {
            year: video['year'],
            movie: video['movie'],
            file: video['file'],
            directors: video['directors'],
            actors: video['actors'],
        }));
        this.setState({videos});
    }

    handleGroupData(data) {
        let groups = new Map();
        [...Tabs.keys()].forEach(tab => {
            groups.set(tab, this.state.groups.get(tab));
        })

        let tabGroupArray = [];
        data.forEach(group => tabGroupArray.push([
            this.state.browserState.tab === 'years' ? group['year'] : group['id'],
            {
                title: this.state.browserState.tab === 'years' ? group['year'] : group['name'],
                videos: group['videos'].split(','),
            }
        ]));

        let tabGroups = new Map(this.sortTabGroup(tabGroupArray));

        groups.set(this.state.browserState.tab, tabGroups);

        if (this.state.browserState.group) {
            let browserState = {
                ...this.state.browserState,
                groupName: tabGroups.get(this.state.browserState.group).title,
            };
            Document.setTitle(browserState);
            this.setState({ groups, browserState });
        } else {
            let browserState = {
                ...this.state.browserState,
                group: tabGroups.keys().next().value,
                groupName: tabGroups.values().next().value.title,
            };
            Document.setState(browserState);
            this.setState({ groups, browserState });
        }
    }

    handleTabChange(tab) {
        let browserState = {
            ...this.state.browserState,
            tab: tab,
            group: this.state.groups.get(tab) === undefined
                ? undefined
                : this.state.groups.get(tab).keys().next().value,
            groupName: this.state.groups.get(tab) === undefined
                ? undefined
                : this.state.groups.get(tab).values().next().value.title,
        };
        if (browserState.group !== undefined) {
            Document.setState(browserState);
        }
        this.setState({ browserState });
    }

    handleGroupChange(group) {
        let browserState = {
            ...this.state.browserState,
            group: group,
            groupName: this.state.groups.get(this.state.browserState.tab).get(group).title,
        };
        Document.setState(browserState);
        this.setState({ browserState });
    }

    sortTabGroup(tabGroups) {
        tabGroups.sort((a, b) => {
            if (this.state.browserState.tab === 'years') {
                if (a < b) {
                    return 1;
                } else if (a > b) {
                    return -1;
                }
            } else if (a[1].videos.length < b[1].videos.length) {
                return 1;
            } else if (a[1].videos.length > b[1].videos.length) {
                return -1;
            } else if (a[1].title > b[1].title) {
                return 1;
            } else if (a[1].title < b[1].title) {
                return -1;
            }
            return 0;    
        });
        return tabGroups;
    }

    render() {
        if (this.state.videos === undefined) {
            return <DataApi key='videos' resource='videos' handleData={this.handleData} />
        }

        if (this.state.groups.get(this.state.browserState.tab) === undefined) {
            return (
                <DataApi
                    key={this.state.browserState.tab}
                    resource={this.state.browserState.tab}
                    handleData={this.handleGroupData}
                />
            );
        }

        return (
            <div>
                <TabSelector
                    selectedTab={this.state.browserState.tab}
                    onTabChange={this.handleTabChange}
                />
                <div className='tab-box'>
                    <GroupSelector
                        groups={this.state.groups.get(this.state.browserState.tab)}
                        selectedGroup={this.state.browserState.group}
                        onGroupChange={this.handleGroupChange}
                    />
                    <Videos
                        videos={this.state.videos}
                        videoSelection={
                            this.state.groups.get(this.state.browserState.tab).get(this.state.browserState.group).videos
                        }
                    />
                </div>
            </div>
        )
    }
}
