import './tab-selector.css'
import React from 'react'
import { Tabs } from '../common/tabs'

export default class TabSelector extends React.Component {
    constructor(props) {
        super(props);
        this.handleTabButtonClick = this.handleTabButtonClick.bind(this);

        this.state = {
            selectedTab: undefined,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedTab === state.selectedTab) {
            return {};
        }
        return {
            selectedTab: props.selectedTab,
        };
    }

    handleTabButtonClick(event) {
        let tab = event.target.id;
        this.props.onTabChange(tab);
    }

    render() {
        return (
            <div className='tab-selector-row'>
                {
                    [...Tabs.keys()].map(tab =>
                        <button
                            id={tab}
                            key={tab}
                            type='button'
                            className={this.state.selectedTab === tab ? 'tab-button-selected' : 'tab-button'}
                            onClick={this.handleTabButtonClick}
                        >
                            {Tabs.get(tab).title}
                        </button>
                    )
                }
            </div>
        )
    }
}
