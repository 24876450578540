import './footer.css'
import React from 'react';
import * as Constants from '../common/constants'

export default function Footer(props) {
    return (
        <footer className='footer-line'>
            2024 {Constants.siteName}
        </footer>
    );
}
