import './app.css'
import React from 'react'
import Home from './home'
import Footer from './frame/footer'
import Title from './frame/title'

export default class App extends React.Component {

    render() {
        return (
            <div>
                <div className='header-box'>
                    <Title />
                </div>
                <div className='content-box'>
                    <Home />
                </div>
                <div className='footer-box'>
                    <Footer />
                </div>
            </div>
        );
    }
}
