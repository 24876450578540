export const Tabs = new Map([
    ['years', {
        'title': 'By Year',
        'description': 'Movie clips for year - ',
    }],
    ['directors', {
        'title': 'By Director',
        'description': 'Movide clips by director - ',
    }],
    ['actors', {
        'title': 'By Actress',
        'description': 'Movie clips with actress - ',
    }],
]);
