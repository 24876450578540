import React from 'react';

export default class DataApi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }

    componentDidMount() {
        let url = 'https://api.beautiesinmovies.com/data/' + this.props.resource;
        fetch(url)
            .then(result => result.json())
            .then(
                (data) => {
                    if (data.length === 0 || data[0] === undefined) {
                        this.setState({ message: 'Invalid data retrieved' });
                    } else {
                        this.props.handleData(data);
                    }
                },
                (error) => { this.setState({ error }); }
            )
    }

    render() {
        if (!this.state.error) {
            return undefined;
        }
        return <div>Data retrieval error: {this.state.error.message}</div>;
    }
}
