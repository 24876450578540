import './title.css'
import React from 'react';
import * as Constants from '../common/constants'

export default function Title(props) {
    return (
        <header>
            <div className='main-title'><a className='title-link' href='/'>{Constants.siteName}</a></div>
            <div className='sub-title'>Nudity and sexuality in old mainstream movies, cut into short video clips</div>
        </header>
    );
}
